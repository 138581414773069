<AppSnackBar
  :message="showMsg"
  :notificationType="'success'"
  v-if="isClubUpdated"
></AppSnackBar>
<app-loader v-if="clubUpdateLoading"></app-loader>
<div class="dashboard-right-inner">
  <Breadcrumb :id="$route.params.id" :type="$route.query.type" />
  <div class="max-height-100--60 overflow-y-auto">
    <div
      class="left d-flex w-100-vsm mb-10-vsm bg-white px-24 border-bottom-normal-1"
    >
      <div
        :class=" activeTab == tab.name  ? 'btn-active' : ''"
        :ref="tab.name"
        v-for="tab in tabNames"
      >
        <kbutton
          :ref="tab.name"
          @click="switchTabHandlers(tab.name)"
          :theme-color="'primary'"
          :fill-mode="'flat'"
          :class="'radius-0 color-black font-13 font-w-500 py-10 px-17'"
          >{{tab.value == 'Audience' ? `Audience (${this.audienceCount})` :
          tab.value}}
        </kbutton>
      </div>
    </div>
    <div class="dashboard-content p-0">
      <div v-if="isPlatformAdmin">
        <component :is="activeTab"></component>
      </div>
      <div v-if="!isPlatformAdmin">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center border-top-normal-1 border-right-normal-1 border-left-normal-1 p-2"
        >
          <div class="left d-flex">
            <div
              :class=" activeTab == tab.name  ? 'btn-active' : ''"
              :ref="tab.name"
              v-for="tab in tabNamesForUser"
            >
              <kbutton
                :ref="tab.name"
                @click="switchTabHandlers(tab.name)"
                :theme-color="'primary'"
                :fill-mode="'flat'"
                :class="'radius-0 color-black font-13 font-w-500 py-10 px-17'"
                >{{tab.value}}
              </kbutton>
            </div>
          </div>
        </div>
        <component @updateAudienceCount="updateAudienceCount" :is="activeTab"></component>
      </div>
    </div>
  </div>
</div>
