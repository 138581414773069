<AppSnackBar
  :message="showMsg.Message"
  :notificationType="showMsg.type"
  v-if="isShowMsg"
></AppSnackBar>
<app-loader v-if="isLoading || isExportLoading || loader"></app-loader>
<div class="">
  <div class="dashboard-content p-20">
    <div v-if="!isOpenConfigureFields">
      <h1>{{part}}</h1>
      <div
        v-if="allAudienceData?.items?.length==0 && !isLoading && !isSearching && !searchWord?.length && !isTagID && !isSubscriberStatusID"
        class="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 min-h-100vh--241"
      >
        <svg
          width="217"
          height="184"
          viewBox="0 0 217 184"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="0.05" cx="109" cy="92" r="92" fill="#253858" />
          <g filter="url(#filter0_bdd_3058_77311)">
            <rect
              x="31.9999"
              y="51"
              width="155"
              height="103"
              rx="1.93432"
              fill="#DEDEDE"
              fill-opacity="0.13"
              shape-rendering="crispEdges"
            />
          </g>
          <g filter="url(#filter1_bdd_3058_77311)">
            <rect
              x="27.9999"
              y="42"
              width="162"
              height="108"
              rx="1.88497"
              fill="#DEDEDE"
              fill-opacity="0.13"
              shape-rendering="crispEdges"
            />
          </g>
          <g filter="url(#filter2_dd_3058_77311)">
            <rect
              x="22.9999"
              y="31"
              width="171"
              height="115"
              rx="3.69388"
              fill="white"
            />
          </g>
          <rect
            x="33.9999"
            y="121"
            width="40"
            height="7"
            rx="2"
            fill="#D1E1F9"
            fill-opacity="0.74"
          />
          <rect
            x="87.9999"
            y="121"
            width="40"
            height="7"
            rx="2"
            fill="#A4C3F3"
          />
          <rect
            x="143"
            y="121"
            width="40"
            height="7"
            rx="2"
            fill="#D1E1F9"
            fill-opacity="0.74"
          />
          <line
            x1="33.9999"
            y1="107.234"
            x2="182.679"
            y2="107.234"
            stroke="black"
            stroke-opacity="0.04"
            stroke-width="0.923471"
          />
          <rect
            x="77.9999"
            y="75"
            width="60.0256"
            height="7.36167"
            rx="3.68083"
            fill="#A4C3F3"
          />
          <rect
            opacity="0.5"
            x="85.9999"
            y="88.8203"
            width="44.9329"
            height="4.76792"
            rx="2.38396"
            fill="#A4C3F3"
          />
          <rect
            x="93.9999"
            y="41"
            width="27.7041"
            height="27.7041"
            rx="13.8521"
            fill="#A4C3F3"
          />
          <defs>
            <filter
              id="filter0_bdd_3058_77311"
              x="23.9607"
              y="42.9609"
              width="171.078"
              height="119.078"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImage" stdDeviation="4.01957" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_3058_77311"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1.81524" />
              <feGaussianBlur stdDeviation="1.81524" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_backgroundBlur_3058_77311"
                result="effect2_dropShadow_3058_77311"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4.01957" />
              <feGaussianBlur stdDeviation="2.00978" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
              />
              <feBlend
                mode="normal"
                in2="effect2_dropShadow_3058_77311"
                result="effect3_dropShadow_3058_77311"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect3_dropShadow_3058_77311"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_bdd_3058_77311"
              x="20.205"
              y="34.2051"
              width="177.59"
              height="123.59"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImage" stdDeviation="3.89744" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_3058_77311"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1.76009" />
              <feGaussianBlur stdDeviation="1.76009" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_backgroundBlur_3058_77311"
                result="effect2_dropShadow_3058_77311"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="3.89744" />
              <feGaussianBlur stdDeviation="1.94872" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
              />
              <feBlend
                mode="normal"
                in2="effect2_dropShadow_3058_77311"
                result="effect3_dropShadow_3058_77311"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect3_dropShadow_3058_77311"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_dd_3058_77311"
              x="0.836578"
              y="16.2245"
              width="215.327"
              height="159.327"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="7.38777" />
              <feGaussianBlur stdDeviation="11.0816" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.04 0 0 0 0 0.168627 0 0 0 0 0.36 0 0 0 0.08 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_3058_77311"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1.84694" />
              <feGaussianBlur stdDeviation="1.84694" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_dropShadow_3058_77311"
                result="effect2_dropShadow_3058_77311"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect2_dropShadow_3058_77311"
                result="shape"
              />
            </filter>
          </defs>
        </svg>

        <p class="font-14 color-light2 mb-4">
          No audience in this list. Click Add Audience to start adding audience.
        </p>
        <kbutton
          v-if="this.segmentId == 0"
          :disabled="!hasCreateAccess()"
          :theme-color="'primary'"
          :class="''"
          @click="btnAddAudience"
          >Add Audience
        </kbutton>
      </div>
      <template v-if="checkedColumns?.length">
        <Grid
          v-if="(allAudienceData?.items?.length>0 || searchWord?.length || isSearching || isTagID || isSubscriberStatusID)"
          ref="grid"
          :class="'first-col-checkbox-center premium-sub-list table-design-2 th-td-checkbox-style-2 mb-60'"
          :data-items="allAudienceData?.items"
          :take="take"
          :skip="skip"
          :total="total"
          :pageable="gridPageable"
          :selectable="true"
          :selected-field="selectedField"
          :columns="columns"
          @selectionchange="onSelectionChange"
          @datastatechange="dataStateChange"
          @headerselectionchange="onHeaderSelectionChange"
          :sortable="true"
          :sort="sort"
          @sortchange="sortChangeHandler"
        >
          <toolbar>
            <div
              class="d-flex flex-wrap align-items-start justify-content-between w-100 mt-1px"
            >
              <div
                class="search-box-wrap mb-3 mb-md-2 d-flex align-items-center w-100-1270"
              >
                <span class="k-icon k-i-zoom"></span>
                <span class="k-textbox k-grid-search k-display-flex">
                  <k-input
                    :class="'mb-0 w-100-vsm max-w-220 max-w-100-vsm'"
                    placeholder="Search"
                    :value="searchWord"
                    @input="onSearch"
                  >
                  </k-input>
                </span>
              </div>
              <div class="d-flex flex-wrap gap-2 gap-md-3 w-100-1270">
                <DropDownButton
                  v-if="selectedItems.length > 0 && this.segmentId == 0"
                  :disabled="!hasEditAccess()"
                  :button-class="'border-normal-1 text-color d-flex flex-row-reverse bg-white hvr-bg-primary'"
                  :class="'w-100-vsm'"
                  :icon="'chevron-down'"
                  :theme-color="'primary'"
                  :fill-mode="'outline'"
                  :popup-settings="popupSettings"
                  :items="actionDropDown[selectedUserStatus]"
                  :text="'Action'"
                  @itemclick="onActionClick"
                />
                <kbutton
                  v-if="selectedItems.length > 0 && this.segmentId == 0"
                  :disabled="!hasDeleteAccess()"
                  :theme-color="'primary'"
                  :fill-mode="'outline'"
                  :class="'w-100-vsm border-normal-1 text-color-red-2 bg-white hvr-bg-red-2 hvr-border-red-2 mt-1px'"
                  :icon="'delete'"
                  @click="deleteModal"
                >
                  Delete
                </kbutton>
                <div
                  v-if="!selectedItems?.length"
                  class="d-flex flex-wrap gap-3"
                >
                  <div class="w-100-vsm mb-9">
                    <span class="d-flex flex-wrap align-items-center w-100-vsm">
                      <label
                        class="font-13 font-w-500 mb-0 pe-2 w-100-vsm mb-4-vsm"
                        >Filter by Status</label
                      >
                      <dropdownlist
                        :class="'w-100-vsm'"
                        :style="{ width: '140px' }"
                        :data-items="statusList"
                        :text-field="'text'"
                        :data-item-key="'id'"
                        :value="selectedStatus"
                        @change="handleValueChange($event.value, 'selectedStatus')"
                      >
                      </dropdownlist>
                    </span>
                  </div>
                  <div v-if="!selectedItems?.length" class="position-relative">
                    <kbutton
                      @click="columnFilterHandler"
                      ref="buildFromScratchRef"
                      :fill-mode="'outline'"
                      :class="'h-38 w-38 border-normal-1 text-color bg-white hvr-bg-primary'"
                      :theme-color="'primary'"
                      :icon="'table-properties'"
                    >
                    </kbutton>
                    <Popup
                      :anchor="'buildFromScratchRef'"
                      :show="columnFilterPopupVisibility"
                      :popup-class="'premium-subscribe-filter-popup column-filter-popup w-219 radius-8'"
                    >
                      <div
                        v-click-outside="() => this.columnFilterPopupVisibility = false"
                        class="p-16 d-flex flex-wrap flex-column gap-3"
                      >
                        <template
                          v-for="(item,index) in allColumns"
                          :key="index"
                        >
                          <checkbox
                            :class="'font-16 font-w-500 cursor-pointer checkbox-style-2'"
                            :label="item.title"
                            :value="item.isChecked"
                            @change="onColumnSelectionChange($event.value, index)"
                          />
                        </template>
                      </div>
                    </Popup>
                  </div>
                  <div class="right w-100-vsm">
                    <buttongroup
                      v-if="this.segmentId == 0"
                      :class="'w-100-vsm'"
                      class="d-flex gap-0"
                    >
                      <kbutton
                        :disabled="!hasCreateAccess()"
                        :theme-color="'primary'"
                        :class="'btn-radius-8-0-0-8 w-100-vsm'"
                        @click="btnAddAudience"
                        >Add Audience
                      </kbutton>
                      <DropDownButton
                        :disabled="!hasCreateAccess()"
                        :class="'btn-size-38'"
                        :buttonClass="'btn-radius-0-8-8-0 btn-bg-dark-blue'"
                        :theme-color="'primary'"
                        :size="''"
                        :popup-settings="popupSettings"
                        :items="audienceDropDown"
                        :icon="'arrow-60-down'"
                        @itemclick="onChangeDrodownImportExport"
                      />
                    </buttongroup>
                    <kbutton
                      v-if="this.segmentId != 0"
                      :disabled="!hasCreateAccess()"
                      :fill-mode="'outline'"
                      :theme-color="'primary'"
                      :class="'h-38 border-normal-1 text-color bg-white hvr-bg-primary'"
                      @click="this.exportList"
                      >Export Audience
                    </kbutton>
                  </div>
                </div>
              </div>
            </div>
          </toolbar>
          <template
            v-for="(item,index) in dateCells"
            :key="index"
            v-slot:[item.cell]="{ props }"
          >
            <td>
              <div class="d-flex justify-content-between">
                <span> {{getDateLocal(props.dataItem[item.field])}} </span>
              </div>
            </td>
          </template>

          <template v-slot:nameCell="{ props }">
            <td>
              <span
                @click="routeToAudienceDetails(props.dataItem)"
                class="font-14 font-w-500 color-blue cursor-pointer"
                >{{props.dataItem.name}}</span
              >
            </td>
          </template>

          <template v-slot:subscriberStatusCell="{props}">
            <td>
              <div class="d-flex justify-content-between">
                <span
                  v-if="props.dataItem.subscriberStatus==0"
                  class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8"
                  >Non-subscribed</span
                >
                <span
                  v-if="props.dataItem.subscriberStatus==1"
                  class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 live"
                  >Subscribed</span
                >
                <span
                  v-if="props.dataItem.subscriberStatus==2"
                  class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 draft"
                  >Unsubscribed</span
                >
                <span
                  v-if="props.dataItem.subscriberStatus==3"
                  class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 paused"
                  >Cleaned</span
                >
              </div>
            </td>
          </template>
          <GridNoRecords> No audience found. </GridNoRecords>
        </Grid>
      </template>
      <AddAudienceListsModal
        @updateLoader="updateLoader"
        v-if="visibleModal"
        :payloadGenerator="payloadGenerator"
      ></AddAudienceListsModal>
      <ImportDataModal
        v-if="importModalVisible && closeMemberImport"
        @closeMemberImportModal="getCloseMemberImportModal"
        @openMemberMapping="getopenMemberMapping"
        :nullDataInitializer="false"
        @modalHandler="modalHandler"
      >
      </ImportDataModal>
      <importCustomFieldMapping
        :dialogue="false"
        v-if="openMembermapping"
        @closeAllModals="closeAllModals"
        @closeMemberMapping="getopenMemberImport"
        :documentId="importedDocId"
        :nullDataInitializer="false"
        @modalHandler="modalHandler"
      ></importCustomFieldMapping>
      <viewListDeleteModal
        :refetchPayload="refetchPayload"
        v-if="deleteModalVisible"
        :selectedItems="selectedItems"
        @completeRemoveAudienceFromList="completeRemoveAudienceFromList"
      ></viewListDeleteModal>
    </div>

    <div v-if="isOpenConfigureFields">
      <ConfigureFields @cancel="cancel()" @save="save()" />
    </div>
  </div>
</div>

<SetStatusActionModal
  :message="actionWarningMessage"
  :onConfirm="onConfirmActionHandler"
  :actionType="actionType"
  v-if="actionmodalVisible"
/>
