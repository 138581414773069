<template src="./ViewListDeleteModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../../../../../common/AppModal/AppModal";
import { removeAudienceFromList } from "../../../../../../../services/clubDetails/allAudience.service";
import SnackBarModel from "@/models/snackBar/snackBarModel";
export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
  },
  props: {
    selectedItems: {
      type: [],
      required: true,
    },
    refetchPayload: {
      type: Object,
      required: true,
    },
  },
  computed: {
    modalVisible() {
      return this.$store.state.common.modalVisible;
    },
    club() {
      return this.$store.getters.clubDetails;
    },
  },
  data: function () {
    return {
      visibleDialog: true,
      clubId: this.$route.params.id,
    };
  },
  methods: {
    cancelModal() {
      this.$store.commit("MODAL_VISIBLE_COMMON", false);
    },
    deleteClub() {
      let payload = {
        listId: +this.$route.params.listId,
        segmentId: +this.$route.params.segmentId,
        audienceIds: this.selectedItems,
        clubId: this.$route.params.id,
      };

      removeAudienceFromList(payload)
        .then((response) => {
          this.$store.commit("MODAL_VISIBLE_COMMON", false);
          this.getAudience();
          var modelMsg = new SnackBarModel();
          modelMsg.type = "success";
          modelMsg.Class = "status-error";
          modelMsg.Message = "Selected audiences removed from the list.";
          this.$store.dispatch("showNotification", modelMsg);
          this.$emit("completeRemoveAudienceFromList")
        })
        .catch((error) => { });

      // removeAudienceFromList
    },
    getAudience() {
      this.$store.dispatch("getPremiumSubscriberList", this.refetchPayload);
    },
    modalHandler(data) {
      this.$store.commit("MODAL_VISIBLE_COMMON", false);
    },
    isClub() {
      return this.club.isAssociation ? "Association" : "Club";
    },
  },
};
</script>