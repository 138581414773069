<template src="./listWithTabs.html"></template>

<script>
import Breadcrumb from "@/components/common/AppBreadCrumbs/BreadCrumbs.vue";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar";
import AppLoader from "@/components/common/AppLoader/AppLoader";
import { Button } from "@progress/kendo-vue-buttons";
import PremiumSubscribers from "./premiumSubscribers/PremiumSubscribers.vue";
import ListAndSegmentAnalytics from "@/components/clubDetails/audience/common/listAndSegmentAnalytics.vue";
import { Input } from "@progress/kendo-vue-inputs";

export default {
  components: {
    Breadcrumb,
    AppSnackBar,
    AppLoader,
    kbutton: Button,
    PremiumSubscribers,
    "k-input": Input,
    ListAndSegmentAnalytics,
  },
  data() {
    return {
      audienceCount: 0,
      clubId: this.$route.params.id,
      selected: 0,
      isPlatformAdmin: true,
      tabNames: [
        {
          name: "PremiumSubscribers",
          value: "Audience",
          id: 2,
        },
        {
          name: "ListAndSegmentAnalytics",
          value: "Analytics",
          id: 1,
        },
      ],
      tabNamesForUser: [
        {
          name: "PremiumSubscribers",
          value: "Audience",
          id: 2,
        },
        {
          name: "ListAndSegmentAnalytics",
          value: "Analytics",
          id: 1,
        },
      ],
      activeTab: "PremiumSubscribers",
    };
  },
  watch: {
    totalAudience: {
      handler: function (val) {
        console.log(val, "c67");
        if (val > 0) {
          this.audienceCount = val;
        }
      },
    },
  },
  computed: {
    totalAudience() {
      return this.$store.state.list.premiumLists?.size;
    },
    club() {
      return this.$store.getters.clubDetails;
    },
    isClubUpdated() {
      return this.$store.state.club.showMsg;
    },
    showMsg() {
      return this.$store.state.club.status.Message;
    },
    clubUpdateLoading() {
      return this.$store.state.club.loading;
    },
  },
  created() {
    if (this.$route.query.listControlTab) {
      this.activeTab = this.$route.query.listControlTab;
    } else {
      this.updateActiveTabInQueryParams();
    }
  },
  mounted() {
    const clubInfo = JSON.parse(localStorage.getItem("clubInfo"));
    this.audienceCount = this.$route.query.count || 0;

    if ([1, 2].includes(clubInfo?.roleId)) {
      this.isPlatformAdmin = false;
    }

    if ([0, 99].includes(clubInfo?.roleId)) {
      this.isPlatformAdmin = true;
    }
  },

  setup() {},
  methods: {
    updateAudienceCount(count) {
      this.audienceCount = count;
    },
    updateActiveTabInQueryParams() {
      this.$router.push({
        query: {
          ...this.$route.query,
          listControlTab: this.activeTab,
        },
      });
    },
    switchTabHandlers(tabName) {
      this.activeTab = tabName;
      this.updateActiveTabInQueryParams();
    },
    onSelect: function (e) {
      this.selected = e.selected;
    },
    isClub() {
      return this.club.isAssociation ? "Association" : "Club";
    },
  },
};
</script>
