<template src="./AddAudienceListsModal.html"></template>
<style lang="scss">
@import "./AddAudienceListsModal.scss";
</style>

<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { Input } from "@progress/kendo-vue-inputs";
import { Button, ButtonGroup } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import Breadcrumb from "../../../../../../common/AppBreadCrumbs/BreadCrumbs.vue";
import GetAudienceModel from "@/models/audience/allAudience/GetAudienceModel";
import axios from "axios";
import modal from "../../../../../../common/AppModal/AppModal.vue";
import { addAudienceToList } from "@/services/clubDetails/allAudience.service";
import SnackBarModel from "@/models/snackBar/snackBarModel";
import { configuration } from "@/configurationProvider";
import { auth } from "@/services/auth.service";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { getAudiences } from "../../../../../../../services/clubDetails/allAudience.service";
import { getMembersColumnService } from "@/services/clubDetails/member.service";
import moment from "moment";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
export default {
  name: "Tags",
  components: {
    Grid: Grid,
    buttongroup: ButtonGroup,
    toolbar: GridToolbar,
    "k-input": Input,
    checkbox: Checkbox,
    kbutton: Button,
    dropdownlist: DropDownList,
    DropDownButton,
    Breadcrumb,
    modal,
    GridNoRecords,
    AppLoader,
  },
  data() {
    let ttle =
      +this.$route.query.type == 1
        ? "Add Audience from Digital Pass Audience"
        : +this.$route.query.type == 2
        ? "Add Audience"
        : +this.$route.query.type == 3
        ? "All Audience from Voucher Audience."
        : "";
    return {
      loader: false,
      clubId: this.$route.params.id,
      searchWord: "",
      selectedField: "selected",
      importModalVisible: false,
      AddAudienceModalVisible: false,
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 50, 100, 200, 500, 1000],
        previousNext: true,
      },
      selectedItems: [],
      rejectedItems: [],
      listColumns: [],
      isColumnLoading: false,
      forceSelectAll: false,
      dateCells: [],
      allColumns: [],
      sort: [],
      sortBy: "",
      sortDir: "",
      documentIdPass: "",
      selectedMembers: [],
      selectedMemberList: [],
      audienceDropDown: [],
      audienceTagDropDown: [],
      drodownImportExport: [
        { id: 0, text: "Import" },
        { id: 1, text: "Export" },
      ],
      passListStatusValue: { id: 0, text: "All" },
      passTagStatusValue: { id: 0, text: "All" },
      openMembermapping: false,
      modalVisible: false,

      passList: [],

      closeMemberImport: true,
      importedDocId: "",
      isOpenConfigureFields: false,
      timer: null,
      title: ttle,
      isModalLoaderLoading: false,
      allChecked: false,
    };
  },
  props: {
    payloadGenerator: {
      type: Function,
      required: true,
    },
  },
  computed: {
    areAllSelected() {
      if (this.forceSelectAll) {
        return true;
      } else {
        return (
          this.selectedItems?.length === this.allAudienceData.items?.length &&
          this.allAudienceData.items?.length != 0
        );
      }
    },
    columns() {
      return [
        {
          field: "selected",
          headerSelectionValue: this.areAllSelected,
          width: "50px",
        },
        ...this?.listColumns,
      ];
    },
    allAudienceData() {
      if (this.forceSelectAll) {
        let membersData = this.$store.state.allAudience.audiences.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.rejectedItems.indexOf(membersData[key].id) !== -1) {
              membersData[key].selected = false;
            } else {
              membersData[key].selected = true;
            }
          }
        }
        return this.$store.state.allAudience.audiences;
      } else {
        let membersData = this.$store.state.allAudience.audiences.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].id) !== -1) {
              membersData[key].selected = true;
            } else {
              membersData[key].selected = false;
            }
          }
        }
        return this.$store.state.allAudience.audiences;
      }
    },

    fieldData() {
      return this.$store.state.allAudience.fields;
    },
    total() {
      return this.allAudienceData ? this.allAudienceData.size : 0;
    },
    take() {
      return this.allAudienceData ? this.allAudienceData.limit : 0;
    },
    skip() {
      return this.allAudienceData ? this.allAudienceData.offset : 0;
    },
    visibleModal() {
      return this.$store.state.club.modalVisible;
    },
    listData() {
      return this.$store.state.list.lists;
    },
    tagsData() {
      return this.$store.state.tag.tags;
    },
    isLoading() {
      return this.$store.state.allAudience.isLoading;
    },
  },
  mounted() {
    this.$store.commit("SET_ALL_AUDIENCE", []);
    this.getAudience();
    this.getColumns();
  },
  methods: {
    // selectAllLabelGenerator() {
    //   return this.forceSelectAll
    //       ? `Select All (${this.allAudienceData?.size - this.rejectedItems?.length})`
    //       : "Select All (0)";
    // },
    selectAllLabelGenerator() {
      if (this.forceSelectAll) {
        return `Select All (${
          this.allAudienceData?.size - this.rejectedItems?.length
        })`;
      } else {
        return `Select All (${this.selectedItems?.length})`;
      }
    },
    getColumns() {
      this.isColumnLoading = true;
      getMembersColumnService({ clubId: this.clubId })
        .then((res) => {
          this.isColumnLoading = false;
          this.allColumns = res.data;
          this.listColumns = this.culumnGenerator(res.data);
        })
        .catch((err) => {
          this.isColumnLoading = false;
        });
    },
    culumnGenerator(data) {
      let columns = [];

      data.forEach((element) => {
        if (element.isChecked) {
          if (["Name", "Status"].includes(element.title)) {
            columns.push({
              field: element.field,
              title: element.title,
              cell: element.field + "Cell",
              sortable: element?.sortable,
            });
          } else {
            if (element.type == 4) {
              columns.push({
                id: element.id,
                field: element.field,
                title: element.title,
                cell: element.field + "Cell",
                sortable: element?.sortable,
              });

              this.dateCells.push({
                field: element.field,
                title: element.title,
                cell: element.field + "Cell",
              });
            } else {
              columns.push({
                id: element.id,
                field: element.field,
                title: element.title,
                sortable: element?.sortable,
              });
            }
          }
        }
      });
      return columns;
    },
    getDateLocal(date) {
      if (date != null) {
        return moment(date).format("DD-MMM-YYYY");
      } else {
        return "-";
      }
    },
    payloadGenerator() {
      let payload = new GetAudienceModel();
      payload = {
        ...payload,
        clubId: this.clubId,
        searchText: this.searchWord,
        entityType: +this.$route.query.type,
        searchQuery: "",
        exceptListId: +this.$route.params.listId,
      };
      if (this.sortBy && this.sortDir) {
        payload.orderBy = `${this.sortBy} ${this.sortDir}`;
      }
      return payload;
    },
    refetchLists(resetPagination = false) {
      let payload = this.payloadGenerator();

      if (resetPagination) {
        payload = {
          ...payload,
          limit: this.take,
          offset: 0,
        };
      } else {
        payload = {
          ...payload,
          limit: this.take,
          offset: this.skip,
        };
      }

      this.$store.dispatch("getAudience", payload);
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort;
      if (e.sort.length > 0) {
        this.sortBy = e.sort[0].field;
        this.sortDir = e.sort[0].dir;
      } else {
        this.sortBy = "";
        this.sortDir = "";
      }

      this.refetchLists(true);
    },
    getAudience() {
      let payload = {
        clubId: this.clubId,
        entityType: +this.$route.query.type,
        searchQuery: "",
        exceptListId: +this.$route.params.listId,
      };
      this.$store.dispatch("getAudience", payload);
    },
    onHeaderSelectionChange(event) {
      this.allChecked = event.event.target.checked;
      this.allAudienceData.items = this.allAudienceData.items.map((item) => {
        return { ...item, selected: this.allChecked };
      });
      if (this.allChecked) {
        let membersData = this.allAudienceData.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].id) !== -1) {
            } else {
              this.selectedItems.push(membersData[key].id);
            }
          }
        }
      } else {
        let membersData = this.allAudienceData.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (this.selectedItems.indexOf(membersData[key].id) !== -1) {
              let selectedData = this.selectedItems;
              for (const j in selectedData) {
                if (Object.hasOwnProperty.call(selectedData, j)) {
                  if (selectedData[j] === membersData[key].id) {
                    selectedData.splice(j, 1);
                  }
                }
              }
            }
          }
        }
      }
    },
    onSelectionChange(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
      if (this.forceSelectAll) {
        if (event.dataItem.selected === false) {
          this.rejectedItems.push(event.dataItem.id);
        } else {
          if (this.rejectedItems.indexOf(event.dataItem.id) !== -1) {
            let rejectedData = this.rejectedItems;
            for (const key in rejectedData) {
              if (Object.hasOwnProperty.call(rejectedData, key)) {
                if (rejectedData[key] === event.dataItem.id) {
                  rejectedData.splice(key, 1);
                }
              }
            }
            this.rejectedItems = rejectedData;
          }
        }
      } else {
        if (event.dataItem.selected === true) {
          this.selectedItems.push(event.dataItem.id);
        } else {
          if (this.selectedItems.indexOf(event.dataItem.id) !== -1) {
            let selectedData = this.selectedItems;
            for (const key in selectedData) {
              if (Object.hasOwnProperty.call(selectedData, key)) {
                if (selectedData[key] === event.dataItem.id) {
                  selectedData.splice(key, 1);
                }
              }
            }
            this.selectedItems = selectedData;
          }
        }
      }
    },

    onSearch(e) {
      this.searchWord = e.value;
      // this.isModalLoaderLoading = true;
      let model = new GetAudienceModel();
      model.clubId = this.$route.params.id;
      model.entityType = +this.$route.query.type;
      model.searchQuery = e.value;
      model.exceptListId = +this.$route.params.listId;
      model.limit = this.take;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        // this.$store.dispatch("getAudience", model);
        this.loader = true;
        getAudiences(model)
          .then((response) => {
            this.$store.commit("SET_ALL_AUDIENCE", response.data.data);
            // this.isModalLoaderLoading = false;
            this.loader = false;
            let payload = {
              clubId: model.clubId,
              entityType: model.entityType,
            };
          })
          .catch((error) => {
            this.loader = false;
          });
      }, 800);
    },
    dataStateChange: function (event) {
      this.loader = true;
      this.take = event.data.take;
      this.skip = event.data.skip;

      let model = new GetAudienceModel();
      model.clubId = this.$route.params.id;
      model.entityType = +this.$route.query.type;
      model.searchQuery = this.searchWord;
      model.limit = event.data.take;
      model.offset = event.data.skip;
      model.exceptListId = +this.$route.params.listId;
      getAudiences(model)
        .then((response) => {
          this.$store.commit("SET_ALL_AUDIENCE", response.data.data);
          // this.isModalLoaderLoading = false;
          this.loader = false;
          let payload = {
            clubId: model.clubId,
            entityType: model.entityType,
          };
        })
        .catch((error) => {
          this.loader = false;
        });
    },
    createTag() {},
    handleListStatusValue(e) {
      this.passListStatusValue = e.value;
      let model = new GetAudienceModel();
      model.clubId = this.$route.params.id;
      model.entityType = +this.$route.query.type;
      model.searchQuery = "";
      model.listId = this.passListStatusValue.id;
      model.tagId = this.passTagStatusValue.id;
      this.$store.dispatch("getAudience", model);
    },
    handleTagStatusValue(e) {
      this.passTagStatusValue = e.value;
      let model = new GetAudienceModel();
      model.clubId = this.$route.params.id;
      model.entityType = +this.$route.query.type;
      model.searchQuery = "";
      model.listId = this.passListStatusValue.id;
      model.tagId = this.passTagStatusValue.id;
      this.$store.dispatch("getAudience", model);
    },
    newSegment() {},
    btnAddAudience() {
      this.$store.commit("MODAL_VISIBLE", true);
    },
    openModal() {
      this.modalVisible = true;
    },
    openImportModal() {
      this.importModalVisible = true;
      this.closeMemberImport = true;
    },
    save(data) {
      let clubId = this.$route.params.id;
      let model = {
        documentId: data,
        clubId: clubId,
      };
      this.$store.dispatch("importMember", model);
    },
    onChangeDrodownImportExport(e) {
      if (e.itemIndex == 1) {
        this.exportList();
      }
    },
    async exportList() {
      let payload = {
        entityType: +this.$route.query.type,
        clubId: this.$route.params.id,
      };

      let token = null;
      if (configuration.enableIdentityServer) {
        token = await auth.getAccessToken();
      } else {
        token = localStorage.getItem("clubeez_session");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      axios({
        method: "POST",
        responseType: "blob",
        headers: headers,
        data: payload,
      }).then((response) => {
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        let filename = "Members of " + this.getClubName + ".xlsx";
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    modalHandler() {
      this.$store.commit("MODAL_VISIBLE", false);
    },
    getopenMemberMapping(value) {
      this.closeMemberImport = false;
      this.openMembermapping = value.isModalOpen;
      this.importedDocId = value.doccumentId;
    },
    getCloseMemberImportModal(value) {
      this.closeMemberImport = false;
    },
    getopenMemberImport(value) {
      this.closeMemberImport = value;
      this.openMembermapping = false;
    },
    closeAllModals() {
      this.importModalVisible = false;
      this.closeMemberImport = false;
      this.openMembermapping = false;
    },
    getClub(e) {
      this.$store.dispatch("getClub", e);
    },
    openConFigureFields() {
      this.isOpenConfigureFields = true;
    },
    cancel() {
      this.isOpenConfigureFields = false;
    },
    updateLoader(value) {
      this.$emit("updateLoader", value);
    },
    async addAudience() {
      // this.updateLoader(true);
      let model = {
        entityType: +this.$route.query.type,
        clubId: this.$route.params.id,
        listId: +this.$route.params.listId,
        isAll: this.forceSelectAll,
        audienceIds: this.selectedItems,
      };
      try {
        let response = await addAudienceToList(model);
        this.$store.commit("MODAL_VISIBLE", false);
        this.$store.dispatch(
          "getPremiumSubscriberList",
          this.payloadGenerator()
        );
        let modelMsg = new SnackBarModel();
        modelMsg.type = "success";
        modelMsg.Class = "status-error";
        modelMsg.Message = "Selected audiences have been added to the list.";
        this.$store.dispatch("showNotification", modelMsg);
        // this.updateLoader(false);
      } catch (error) {
        // this.updateLoader(false);
      }
    },
  },
};
</script>

<style></style>
