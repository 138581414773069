<template src="./PremiumSubscribers.html"></template>
<style lang="scss">
@import "./PremiumSubscribers.scss";
</style>

<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { Input } from "@progress/kendo-vue-inputs";
import { Button, ButtonGroup } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import AddAudienceListsModal from "../premiumSubscribers/modals/addAudienceListsModal/AddAudienceListsModal";
import Breadcrumb from "../../../../common/AppBreadCrumbs/BreadCrumbs.vue";
import GetAudienceModel from "@/models/audience/allAudience/GetAudienceModel";
import { Checkbox } from "@progress/kendo-vue-inputs";
import viewListDeleteModal from "./modals/viewListDeleteModal/ViewListDeleteModal";
import AppSnackBar from "../../../../common/AppSnackBar/AppSnackBar";
import AppLoader from "../../../../common/AppLoader/AppLoader";
import axios from "axios";
import SnackBarModel from "@/models/snackBar/snackBarModel";
import { configuration } from "@/configurationProvider";
import { auth } from "@/services/auth.service";
import custom from "@/components/common/NoDataRender/NoDataRender.vue";
import {
  getAllAudienceColumnService,
  updateAudienceColumnService,
  updateAudienceSubscriberStatusService,
} from "@/services/clubDetails/allAudience.service";
import moment from "moment/moment";
import { Popup } from "@progress/kendo-vue-popup";
import SetStatusActionModal from "@/components/clubDetails/members/setStatusActionModal/SetStatusActionModal.vue";
import vClickOutside from "click-outside-vue3";
export default {
  name: "Tags",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    SetStatusActionModal,
    custom,
    Grid: Grid,
    buttongroup: ButtonGroup,
    toolbar: GridToolbar,
    "k-input": Input,
    kbutton: Button,
    dropdownlist: DropDownList,
    DropDownButton,
    Breadcrumb,
    AddAudienceListsModal,
    checkbox: Checkbox,
    viewListDeleteModal,
    AppSnackBar,
    AppLoader,
    GridNoRecords,
    Popup,
  },
  data() {
    return {
      refetchPayload: {},
      loader: false,
      itemTagTypes: {
        1: "dpAudience",
        2: "ecAudience",
        3: "vAudience",
      },
      moduleTagTypes: {
        1: "digitalPass",
        2: "emailCampaign",
        3: "voucher",
      },
      childItemCodeType: {
        1: "dpLists",
        2: "ecLists",
        3: "vLists",
      },
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      listNoDataRender: "myTemplate",
      columnFilterPopupVisibility: false,
      isColumnLoading: false,
      checkedColumns: [],
      dateCells: [],
      allColumns: [],
      sort: [],
      sortBy: "",
      sortDir: "",
      clubId: this.$route.params.id,
      searchWord: "",
      selectedField: "selected",
      importModalVisible: false,
      AddAudienceModalVisible: false,
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 50, 100, 200, 500, 1000],
        previousNext: true,
      },
      selectedItems: [],
      documentIdPass: "",
      selectedMembers: [],
      selectedMemberList: [],
      audienceDropDown: [
        // { id: 0, text: "Import Audience" },
        { id: 1, text: "Export Audience" },
      ],
      passTagStatusValue: { id: 0, text: "All" },
      pramsTagId: null,
      forceSelectAll: false,
      openMembermapping: false,
      modalVisible: false,
      audienceTagDropDown: [{ id: 0, text: "All" }],
      passList: [],
      closeMemberImport: true,
      importedDocId: "",
      isOpenConfigureFields: false,
      listName: this.$route.query.listName || null,
      segmentName: this.$route.query.segmentName,
      timer: null,
      isSearching: false,
      isExportLoading: false,
      allChecked: false,
      selectedStatus: { text: "All", id: 0 },
      passSegmentStatusValue: { id: 0, text: "All" },
      statusList: [
        { text: "All", id: 0 },
        { text: "Non-subscribed", id: 1 },
        { text: "Subscribed", id: 2 },
        { text: "Unsubscribed", id: 3 },
        { text: "Cleaned", id: 4 },
      ],
      isTagID: false,
      isSubscriberStatusID: false,
      isSegmentFilter: false,

      listId: this.$route.params.listId,
      segmentId: this.segmentId,
      actionDropDown: {
        0: [
          { id: 1, text: "Subscribed" },
          { id: 2, text: "Unsubscribed" },
          // { id: 0, text: "Non-subscribed" },
          { id: 3, text: "Cleaned" },
        ],
        1: [
          // { id: 1, text: "Subscribed" },
          { id: 2, text: "Unsubscribed" },
          { id: 0, text: "Non-subscribed" },
          { id: 3, text: "Cleaned" },
        ],
        2: [
          { id: 1, text: "Subscribed" },
          // { id: 2, text: "Unsubscribed" },
          { id: 0, text: "Non-subscribed" },
          { id: 3, text: "Cleaned" },
        ],
        3: [
          { id: 1, text: "Subscribed" },
          { id: 2, text: "Unsubscribed" },
          { id: 0, text: "Non-subscribed" },
          // { id: 3, text: "Cleaned" },
        ],
        4: [
          { id: 1, text: "Subscribed" },
          { id: 2, text: "Unsubscribed" },
          { id: 0, text: "Non-subscribed" },
          { id: 3, text: "Cleaned" },
        ],
      },
      selectedUserStatus: 4,
      actionType: "",
      actionWarningMessage: "",
    };
  },
  computed: {
    areAllSelected() {
      return (
        this.selectedItems?.length === this.allAudienceData.items?.length &&
        this.allAudienceData.items?.length != 0
      );
    },
    columns() {
      return [
        {
          field: "selected",
          headerSelectionValue: this.areAllSelected,
        },
        ...this.checkedColumns,
      ];
    },
    allAudienceData() {
      if (this.forceSelectAll) {
        let membersData = this.$store.state.list.premiumLists.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (
              this.rejectedItems.indexOf(membersData[key].audienceId) !== -1
            ) {
              membersData[key].selected = false;
            } else {
              membersData[key].selected = true;
            }
          }
        }
        return this.$store.state.list.premiumLists;
      } else {
        let membersData = this.$store.state.list.premiumLists.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (
              this.selectedItems.indexOf(membersData[key].audienceId) !== -1
            ) {
              membersData[key].selected = true;
            } else {
              membersData[key].selected = false;
            }
          }
        }
        return this.$store.state.list.premiumLists;
      }
    },
    fieldData() {
      return this.$store.state.allAudience.fields;
    },
    total() {
      return this.allAudienceData ? this.allAudienceData.size : 0;
    },
    take() {
      return this.allAudienceData ? this.allAudienceData.limit : 0;
    },
    skip() {
      return this.allAudienceData ? this.allAudienceData.offset : 0;
    },
    visibleModal() {
      return this.$store.state.club.modalVisible;
    },
    tagsData() {
      return this.$store.state.tag.tags;
    },
    segmentsData() {
      return this.$store.state.segments.segmentsLists;
    },
    deleteModalVisible() {
      return this.$store.state.common.modalVisible;
    },
    isShowMsg() {
      return this.$store.state.common.showMsg;
    },
    showMsg() {
      return this.$store.state.common.status;
    },
    isLoading() {
      return this.$store.state.common.loader;
    },
    actionmodalVisible() {
      return this.$store.state.members.actionModalVisible;
    },
  },
  mounted() {
    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to view campaigns.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }

    this.listId = this.$route.params?.listId || 0;
    this.segmentId = this.$route.query?.segmentId || 0;
    this.segmentName = this.$route.query?.segmentName || null;

    this.$store.commit("SET_ALL_PREMIUM_LIST", []);
    this.getAudience();
    this.getColumns();
    this.getTags();
  },
  unmounted() {
    this.$store.commit("SET_ALL_PREMIUM_LIST", []);
  },
  methods: {
    updateLoader(value) {
      console.log(value, "d45x");
      this.loader = value;
    },
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    routeToAudienceDetails(audienceInfo) {
      console.log(audienceInfo);
      let type = +this.$route.query.type;

      if (type == 1)
        this.$router.push(
          "/club-details/" +
            this.clubId +
            "/digitalpass-allAudience" +
            "/audience-details/" +
            audienceInfo?.clubUserId +
            "?type=" +
            type +
            "&audienceId=" +
            audienceInfo?.audienceId
        );
      else if (type == 2)
        this.$router.push(
          "/club-details/" +
            this.clubId +
            "/campaign-allAudience" +
            "/audience-details/" +
            audienceInfo?.clubUserId +
            "?type=" +
            type +
            "&audienceId=" +
            audienceInfo?.audienceId
        );
      else if (type == 3)
        this.$router.push(
          "/club-details/" +
            this.clubId +
            "/voucher-allAudience" +
            "/audience-details/" +
            audienceInfo?.clubUserId +
            "?type=" +
            type +
            "&audienceId=" +
            audienceInfo?.audienceId
        );
    },
    getAudience() {
      this.refetchGridData(true);
    },
    getTags() {
      var model = {
        clubId: this.$route.params.id,
        entityType: +this.$route.query.type,
      };
      this.$store.dispatch("getTags", model);
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort;
      if (e.sort.length > 0) {
        this.sortBy = e.sort[0].field;
        this.sortDir = e.sort[0].dir;
      } else {
        this.sortBy = "";
        this.sortDir = "";
      }

      this.refetchGridData(true);
    },
    handleValueChange(value, field) {
      this.isSearching = true;
      this[field] = value;
      this.refetchGridData(true);
    },
    columnFilterHandler() {
      this.columnFilterPopupVisibility = !this.columnFilterPopupVisibility;
    },
    onColumnSelectionChange(value, index) {
      this.allColumns[index].isChecked = value;
      this.updateColumns(
        {
          id: this.allColumns[index].id,
          isChecked: value,
        },
        index
      );
    },
    updateColumns(columnData, index) {
      this.isColumnLoading = true;
      let payload = {
        clubId: this.clubId,
        entityType: +this.$route.query.type,
        ...columnData,
      };
      updateAudienceColumnService(payload)
        .then((res) => {
          this.isColumnLoading = false;
          this.checkedColumns = this.culumnGenerator(this.allColumns);
        })
        .catch((error) => {
          this.isColumnLoading = false;
          this.allColumns[index].isChecked = !columnData.isChecked;
          this.$store.commit("SHOW_NOTIFICATION", {
            message:
              error.response.data?.Status?.Message ||
              "An error occurred while updating the columns. Please try again later.",
            type: "error",
          });
        });
    },
    culumnGenerator(data) {
      let columns = [];

      data.forEach((element) => {
        if (element.isChecked) {
          if (["Name", "Status"].includes(element.title)) {
            columns.push({
              field: element.field,
              title: element.title,
              cell: element.field + "Cell",
              sortable: element?.sortable,
              width: "150px",
            });
          } else {
            if (element.type == 4) {
              columns.push({
                id: element.id,
                field: element.field,
                title: element.title,
                cell: element.field + "Cell",
                sortable: element?.sortable,
                width: "150px",
              });

              this.dateCells.push({
                field: element.field,
                title: element.title,
                cell: element.field + "Cell",
              });
            } else {
              columns.push({
                id: element.id,
                field: element.field,
                title: element.title,
                sortable: element?.sortable,
              });
            }
          }
        }
      });

      return columns;
    },
    getColumns() {
      this.isColumnLoading = true;
      getAllAudienceColumnService({
        clubId: this.clubId,
        entityType: this.$route.query.type,
      })
        .then((res) => {
          this.isColumnLoading = false;
          this.allColumns = res.data;
          this.checkedColumns = this.culumnGenerator(res.data);
        })
        .catch((err) => {
          this.isColumnLoading = false;
        });
    },
    payloadGenerator() {
      let payload = new GetAudienceModel();
      payload = {
        ...payload,
        clubId: this.clubId,
        entityType: +this.$route.query.type,
        searchQuery: this.searchWord,
        listId: this.$route.params.listId,
        segmentId: this.segmentId,
        ...(this.selectedStatus.id != 0 && {
          subscriberStatusFilter: this.selectedStatus.id,
        }),
      };

      if (this.sortBy && this.sortDir) {
        payload.orderBy = `${this.sortBy} ${this.sortDir}`;
      }
      return payload;
    },
    refetchGridData(resetPagination = false) {
      let payload = this.payloadGenerator();
      if (resetPagination) {
        payload = {
          ...payload,
          limit: this.take,
          offset: 0,
        };
      }
      this.$store.dispatch("getPremiumSubscriberList", payload);
    },
    getDateLocal(date) {
      if (date != null) {
        return moment(date).format("DD-MMM-YYYY");
      } else {
        return "-";
      }
    },
    onHeaderSelectionChange(event) {
      this.allChecked = event.event.target.checked;
      this.allAudienceData.items = this.allAudienceData.items.map((item) => {
        return { ...item, selected: this.allChecked };
      });
      if (this.allChecked) {
        let membersData = this.allAudienceData.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (
              this.selectedItems.indexOf(membersData[key].audienceId) !== -1
            ) {
            } else {
              this.selectedItems.push(membersData[key].audienceId);
            }
          }
        }
      } else {
        let membersData = this.allAudienceData.items;
        for (const key in membersData) {
          if (Object.hasOwnProperty.call(membersData, key)) {
            if (
              this.selectedItems.indexOf(membersData[key].audienceId) !== -1
            ) {
              let selectedData = this.selectedItems;
              for (const j in selectedData) {
                if (Object.hasOwnProperty.call(selectedData, j)) {
                  if (selectedData[j] === membersData[key].audienceId) {
                    selectedData.splice(j, 1);
                  }
                }
              }
            }
          }
        }
      }
    },
    onSelectionChange(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
      if (this.forceSelectAll) {
        if (event.dataItem.selected === false) {
          this.rejectedItems.push(event.dataItem.audienceId);
        } else {
          if (this.rejectedItems.indexOf(event.dataItem.audienceId) !== -1) {
            let rejectedData = this.rejectedItems;
            for (const key in rejectedData) {
              if (Object.hasOwnProperty.call(rejectedData, key)) {
                if (rejectedData[key] === event.dataItem.audienceId) {
                  rejectedData.splice(key, 1);
                }
              }
            }
            this.rejectedItems = rejectedData;
          }
        }
      } else {
        if (event.dataItem.selected === true) {
          this.selectedItems.push(event.dataItem.audienceId);
        } else {
          if (this.selectedItems.indexOf(event.dataItem.audienceId) !== -1) {
            let selectedData = this.selectedItems;
            for (const key in selectedData) {
              if (Object.hasOwnProperty.call(selectedData, key)) {
                if (selectedData[key] === event.dataItem.audienceId) {
                  selectedData.splice(key, 1);
                }
              }
            }
            this.selectedItems = selectedData;
          }
        }
      }
    },
    onSearch(e) {
      this.isSearching = true;
      this.searchWord = e.value;
      this.$store.commit("SET_LOADER", true);
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.refetchGridData(true);
      }, 800);
    },
    onActionClick(e) {
      if (e?.item?.id == 0) {
        this.actionType = 0;
        this.actionWarningMessage = `You are about to change the subscription status of ${this.selectedItems?.length} audience(s) to non-subscribe. Are you sure you want to proceed with this action?`;
      }
      if (e?.item?.id == 1) {
        this.actionType = 1;
        this.actionWarningMessage = `You are about to change the subscription status of ${this.selectedItems?.length} audience(s) to subscribe. Are you sure you want to proceed with this action?`;
      }
      if (e?.item?.id == 2) {
        this.actionType = 2;
        this.actionWarningMessage = `You are about to change the subscription status of ${this.selectedItems?.length} audience(s) to unsubscribe. Are you sure you want to proceed with this action?`;
      }
      if (e?.item?.id == 3) {
        this.actionType = 3;
        this.actionWarningMessage = `You are about to change the subscription status of ${this.selectedItems?.length} audience(s)  to cleaned. Are you sure you want to proceed with this action?`;
      }
      // ;
      const timerId = setTimeout(() => {
        this.$store.commit("ACTION_MODAL_VISIBLE", true);
        clearTimeout(timerId);
      }, 100);
    },
    onConfirmActionHandler() {
      this.$store.commit("ACTION_MODAL_VISIBLE", false);

      const payload = {
        clubId: this.$route.params.id,
        ids: this.selectedItems,
        entityType: +this.$route.query.type,
        subscriberStatus: this.actionType,
      };
      this.loader = true;
      updateAudienceSubscriberStatusService(payload)
        .then((res) => {
          this.selectedItems = [];
          this.loader = false;
          this.$store.commit("SHOW_NOTIFICATION", {
            message:
              "The subscription status of the selected audience(s) has been successfully updated.",
            type: "success",
          });
          this.getAudience();
        })
        .catch((err) => {
          this.loader = false;
          this.$store.commit("SHOW_NOTIFICATION", {
            message:
              "An error occurred while updating the subscription status of the selected audience(s). Please try again later.'.",
            type: "error",
          });
        });
    },
    dataStateChange: function (event) {
      this.take = event.data.take;
      this.skip = event.data.skip;

      const payload = this.payloadGenerator();
      payload.limit = event.data.take;
      payload.offset = event.data.skip;
      this.$store.dispatch("getPremiumSubscriberList", payload);
    },
    btnAddAudience() {
      this.$store.commit("MODAL_VISIBLE", true);
    },
    save(data) {
      let clubId = this.$route.params.id;
      let model = {
        documentId: data,
        clubId: clubId,
      };
      this.$store.dispatch("importMember", model);
    },
    async exportList() {
      this.isExportLoading = true;
      var payload = {
        entityType: +this.$route.query.type,
        clubId: this.$route.params.id,
        listId: this.$route.params.listId,
        segmentId: this.segmentId,
      };

      let token = null;
      if (configuration.enableIdentityServer) {
        token = await auth.getAccessToken();
      } else {
        token = localStorage.getItem("clubeez_session");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      axios({
        url: `${configuration.apiBaseUrl}/api/v1/audience/list/${
          this.$route.params.listId || 0
        }/export`,
        method: "POST",
        responseType: "blob",
        headers: headers,
        data: payload,
      })
        .then((response) => {
          this.isExportLoading = false;
          var model = new SnackBarModel();
          model.type = "success";
          model.Message = "Audience exported successfully.";
          if (response) this.$store.dispatch("showNotification", model);
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let name = this.listName || this.segmentName;
          let filename =
            +this.$route.query.type == 3
              ? "Audience list of voucher (" + name + ").xlsx"
              : +this.$route.query.type == 2
              ? "Audience list of email campaign (" + name + ").xlsx"
              : "Audience list of digital pass (" + name + ").xlsx";
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          this.$store.commit("SET_LOADER", false);
          var model = new SnackBarModel();
          model.type = "error";
          model.Message = "No audience to export.";
          this.$store.dispatch("showNotification", model);
        });
    },
    modalHandler(data) {
      this.importModalVisible = data;
    },
    getopenMemberMapping(value) {
      this.closeMemberImport = false;
      this.openMembermapping = value.isModalOpen;
      this.importedDocId = value.doccumentId;
    },
    getCloseMemberImportModal(value) {
      this.closeMemberImport = false;
    },
    getopenMemberImport(value) {
      this.closeMemberImport = value;
      this.openMembermapping = false;
    },
    closeAllModals() {
      this.importModalVisible = false;
      this.closeMemberImport = false;
      this.openMembermapping = false;
    },
    openConFigureFields() {
      this.isOpenConfigureFields = true;
    },
    cancel() {
      this.isOpenConfigureFields = false;
    },
    deleteModal() {
      this.refetchPayload = this.payloadGenerator();
      this.$store.commit("MODAL_VISIBLE_COMMON", true);
    },
    completeRemoveAudienceFromList() {
      this.selectedItems = [];
    },
    onChangeDrodownImportExport(e) {
      if (e.itemIndex == 0) {
        this.exportList();
      }
    },
  },
};
</script>
<style></style>
